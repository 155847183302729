import React from "react"
import Components from "../storyblok/Components"
import SbEditable from "storyblok-react"
import config from "../../gatsby-config"

const loadStoryblokBridge = function(cb) {
  let sbConfigs = config.plugins.filter(item => {
    return item.resolve === "gatsby-source-storyblok"
  })
  let sbConfig = sbConfigs.length > 0 ? sbConfigs[0] : {}
  let script = document.createElement("script")
  script.type = "text/javascript"
  script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${sbConfig.options.accessToken}`
  script.onload = cb
  document.getElementsByTagName("head")[0].appendChild(script)
}

const getParam = function(val) {
  var result = ""
  var tmp = []

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function(item) {
      tmp = item.split("=")
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1])
      }
    })

  return result
}

class StoryblokEntry extends React.Component {
  constructor(props) {
    super(props)
    this.state = { story: null, footer: { content: {} } }
  }

  componentDidMount() {
    loadStoryblokBridge(() => {
      this.initStoryblokEvents()
    })
  }

  loadStory(payload) {
    window.storyblok.get(
      {
        slug: getParam("path"),
        version: "draft",
      },
      data => {
        this.setState({ story: data.story })
        // this.loadHeader(data.story.lang)
        this.loadFooter(data.story.lang)
        // this.loadArticles()
      }
    )
  }

  // loadArticles() {
  //   window.storyblok.get(
  //     {
  //       slug: "/",
  //       starts_with: `news/`,
  //       version: "draft",
  //     },
  //     data => {
  //       const articles = data.stories
  //         .map(article => {
  //           // node.content = JSON.parse(node.content)
  //           const slugs = article.full_slug
  //             ? article.full_slug
  //                 .replace(/\/\s*$/, "")
  //                 .split("/")
  //                 .slice(0, 3)
  //             : null
  //           const typeSlug = slugs ? slugs.slice(-2)[0] : null
  //           const type = typeSlug.charAt(0).toUpperCase() + typeSlug.slice(1)

  //           return slugs && slugs.length > 2 && slugs[0] === "news"
  //             ? {
  //                 ...article,
  //                 article: {
  //                   typeSlug: typeSlug,
  //                   type: type,
  //                 },
  //               }
  //             : article
  //         })
  //         .filter(article => article.article)
  //       this.setState({ articles: articles })
  //     }
  //   )
  // }

  // loadHeader(lang) {
  //   const language = lang === "default" ? "" : lang + "/"
  //   window.storyblok.get(
  //     {
  //       slug: `${language}global-navi`,
  //       version: "draft",
  //     },
  //     data => {
  //       this.setState({ header: data.story })
  //     }
  //   )
  // }

  loadFooter(lang) {
    const language = lang === "default" ? "" : lang + "/"
    window.storyblok.get(
      {
        slug: `${language}sections/footer`,
        version: "draft",
      },
      data => {
        this.setState({ footer: data.story })
      }
    )
  }

  initStoryblokEvents() {
    this.loadStory({ storyId: getParam("path") })

    let sb = window.storyblok

    sb.on(["change", "published"], payload => {
      this.loadStory(payload)
    })

    sb.on("input", payload => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        )
        this.setState({ story: payload.story })
      }
    })

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode()
      }
    })
  }

  render() {
    if (this.state.story == null) {
      return <div />
    }

    let story = this.state.story

    let content = story.content
    let footer = this.state.footer.content

    return (
      <SbEditable content={content}>
        {React.createElement(Components(story.content.component), {
          storyID: story.uuid,
          tags: story.tag_list,
          name: story.name,
          slug: story.slug,
          isStartPage: story.is_startpage,
          full_slug: story.full_slug,
          date: story.created_at,
          blok: content,
          footer: footer,
          isEditor: true,
        })}
      </SbEditable>
    )
  }
}

export default StoryblokEntry
