module.exports = {
  siteMetadata: {
    title: `Noice — Du lever bara en gång`,
    description: `En rockmusikal`,
    author: `@noice`,
    siteUrl: `http://noicerockmusikal.se`,
  },
  plugins: [
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-sass`,
    `gatsby-plugin-root-import`,
    `gatsby-plugin-styled-components`,
    `gatsby-plugin-remove-serviceworker`,
    {
      resolve: `gatsby-source-storyblok`,
      options: {
        accessToken: "2oYRDvdfYoDZQ6HF3mPFggtt",
        homeSlug: "home",
        version: process.env.NODE_ENV === "production" ? "published" : "draft",
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`,
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `noice-du-lever-bara-en-gang`,
        short_name: `noice`,
        start_url: `/`,
        background_color: `#000000`,
        theme_color: `#FFFFFF`,
        display: `minimal-ui`,
        icon: `src/assets/images/icon.png`, // This path is relative to the root of the site.
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        excludes: [`/editor/*`],
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: `UA-19907846-8`,
        head: false,
        anonymize: true,
        exclude: [`/editor/**`],
      },
    },
    {
      resolve: `gatsby-plugin-facebook-pixel`,
      options: {
        pixelId: `1820182131477162`,
      },
    },
  ],
}
